import React from 'react';
import useWindowSize from "../../hooks/use_window_size";
import ReactDOM from "react-dom";

interface ReferenceInfo {
    left: number;
    width: number;
}

interface OverlayProps {
    refInfo: ReferenceInfo | null;
    container: HTMLDivElement | null;
}

function OverlayLeft(props: OverlayProps) {
    if (!props.refInfo || !props.container) {
        return null;
    }

    const style: React.CSSProperties = {
        position: 'absolute',
        left: props.refInfo.left - 441.51 + 130,
        top: 0,
        width: '441.51px',
        pointerEvents: 'none'
    };

    return ReactDOM.createPortal(<img src="/images/cny/overlay-left.svg" style={style} className="d-xl-block" alt="" />, props.container);
}

function OverlayRight(props: OverlayProps) {
    if (!props.refInfo || !props.container) {
        return null;
    }

    const style: React.CSSProperties = {
        position: 'absolute',
        left: props.refInfo.left + props.refInfo.width - 96,
        top: 0,
        width: '420px',
        pointerEvents: 'none',
        display: 'none'
    };

    return ReactDOM.createPortal(<img src="/images/cny/overlay-right.svg" style={style} className="d-xl-block" alt="" />, props.container);
}

export default function CnyHomeOverlay() {
    const [refInfo, setRefInfo] = React.useState<ReferenceInfo | null>(null);
    const containerRef = React.useRef<HTMLDivElement | null>(null);
    const referenceElRef = React.useRef<HTMLDivElement | null>(null);

    const windowSize = useWindowSize();

    React.useEffect(() => {
        const div = document.createElement('div');
        div.style.position = 'absolute';
        div.style.zIndex = '1030';
        div.style.pointerEvents = 'none';
        div.style.top = '0';
        div.style.left = '0';
        div.style.height = '100%';
        div.style.width = '100%';
        div.style.overflow = 'hidden';
        containerRef.current = div;
        document.body.append(div);

        return () => {
            document.body.removeChild(div);
        };
    }, []);

    React.useEffect(() => {
        const el = referenceElRef.current;
        if (!el) {
            return;
        }

        const bodyRect = document.body.getBoundingClientRect();
        const elRect = el.getBoundingClientRect();
        setRefInfo({
            left: elRect.left - bodyRect.left,
            width: elRect.width
        });
    }, [windowSize]);

    const containerRefSetter = React.useCallback((el: HTMLDivElement) => {
        if (!el) {
            return;
        }

        referenceElRef.current = el;
        const bodyRect = document.body.getBoundingClientRect();
        const elRect = el.getBoundingClientRect();
        setRefInfo({
            left: elRect.left - bodyRect.left,
            width: elRect.width
        });
    }, []);

    return <>
        <div className="container" ref={containerRefSetter} />
        <OverlayLeft refInfo={refInfo} container={containerRef.current} />
        <OverlayRight refInfo={refInfo} container={containerRef.current} />
    </>;
}