import {QuotationItem, QuotationItemSources, QuotationItemStatuses} from "../types/models/quotation_item";
import {createDinero} from "../service/currency_converter";
import {CartAndQuotationItemWrapper} from "../hooks/use_cart_and_quotation_items";
import {CartItemProductForCart} from "../types/models/cart_item";
import {isUrlAbsolute} from "../utils/url";
import {thumbUrl} from "../utils/image";
import {Product} from "../types/models/product";
import {getStore} from "../store/store";

export function isInternal(item: QuotationItem): item is QuotationItem<QuotationItemStatuses, QuotationItemSources.internal> {
  return item.productSource === 'i';
}

export function isExternal(item: QuotationItem): item is QuotationItem<QuotationItemStatuses, QuotationItemSources.external> {
  return item.productSource === 'e';
}

export function isResolved(item: QuotationItem): item is QuotationItem<QuotationItemStatuses.resolved> {
  return item.status === QuotationItemStatuses.resolved;
}

export function hasVariant(item: QuotationItem, product?: Pick<Product, 'variantPaths'>) {
  if (!product || !isInternal(item)) {
    return false;
  }

  const variantPath = item.productSourceData.variantPath;
  return !!variantPath &&
    !!product.variantPaths[variantPath];
}

export function getInternalQuotationItemPricePerUnit(item: QuotationItem, countryCode: string, product: Pick<Product, 'variantPaths' | 'priceCurrency' | 'price'>) {
  if (!isInternal(item)) {
    throw new Error('Quotation item is not internal');
  }

  if (hasVariant(item, product)) {
    const variantPathInfo = product.variantPaths[item.productSourceData.variantPath!!];
    return createDinero(product.priceCurrency, variantPathInfo.price[countryCode]);
  } else {
    return createDinero(product.priceCurrency, product.price[countryCode]);
  }
}

export function getInternalQuotationItemPrice(item: QuotationItem, countryCode: string, product: Pick<Product, 'variantPaths' | 'priceCurrency' | 'price'>) {
  if (!isInternal(item)) {
    throw new Error('Quotation item is not internal');
  }

  return getInternalQuotationItemPricePerUnit(item, countryCode, product)
    .multiply(item.quantity);
}

function getExternalQuotationItemPrice(item: QuotationItem, countryCode: string) {
  if (!isExternal(item)) {
    throw new Error('Quotation item is not external');
  }

  const state = getStore().getState();
  let appCurrency = 'SGD';
  if (state && state.config.data) {
    appCurrency = state.config.data.app_currency;
  }

  if (item.status === QuotationItemStatuses.resolved) {
    const resolvedItem = item as QuotationItem<QuotationItemStatuses.resolved, QuotationItemSources.external>;
    return createDinero(appCurrency, resolvedItem.statusData.quotedPrice[countryCode])
        .multiply(item.quantity);
  } else {
    return createDinero(appCurrency, 0);
  }
}

export function getQuotationItemPrice(item: QuotationItem, countryCode: string, product?: Pick<Product, 'variantPaths' | 'priceCurrency' | 'price'>) {
  if (item.productSource === QuotationItemSources.internal) {
    if (!product) {
      throw new Error('product is not found for quotation item');
    }

    return getInternalQuotationItemPrice(item, countryCode, product);
  } else {
    return getExternalQuotationItemPrice(item, countryCode);
  }
}

function findQuotationItemIndexById(items: QuotationItem[], id: string) {
  return items.findIndex(item => item._id === id);
}

export function findQuotationItemById(quotationItems: QuotationItem[], id: string) {
  const index = findQuotationItemIndexById(quotationItems, id);
  if (index === -1) {
    return undefined;
  }

  return quotationItems[index];
}

export function isQuotationItemDisabledInCart(quotationItem: QuotationItem, product?: CartItemProductForCart) {
  let itemDisabled = [QuotationItemStatuses.declined, QuotationItemStatuses.pending].includes(quotationItem.status);
  if (!itemDisabled) {
    if (isInternal(quotationItem)) {
      if (product) {
        itemDisabled = product.disabled;
      } else {
        itemDisabled = true;
      }
    }
  }

  return itemDisabled;
}

export function getWrappedItemName(wrappedItem: CartAndQuotationItemWrapper) {
  if (wrappedItem.type === 'quotation_external') {
    return wrappedItem.item.name;
  }

  const {product} = wrappedItem;
  return product.name;
}

export function getWrappedItemVariantInfo(wrappedItem: CartAndQuotationItemWrapper) {
  if (wrappedItem.isQuotation) {
    return getQuotationItemVariantInfo(wrappedItem.item, wrappedItem.product);
  } else {
    const {product} = wrappedItem;
    return {
      variants: product.variants,
      variantPath: wrappedItem.item.variantPath
    };
  }
}

export function getWrappedItemSourceLabel(wrappedItem: CartAndQuotationItemWrapper) {
  if (wrappedItem.type === 'quotation_external') {
    return 'QUOTATION';
  }

  const product = wrappedItem.product;
  let sourceLabel = product.sourceMarketPlace;
  if (sourceLabel === 'local' && product.merchant) {
    sourceLabel = product.merchant.name;
  }

  if (wrappedItem.isQuotation) {
    sourceLabel += ' (QUOTATION)';
  }

  return sourceLabel;
}

export function getQuotationItemVariantInfo(quotationItem: QuotationItem, product?: Pick<Product, 'variants'>) {
  if (product && isInternal(quotationItem)) {
    return {
      variants: product.variants,
      variantPath: quotationItem.productSourceData.variantPath
    };
  }

  return undefined;
}

export function getQuotationItemImage(quotationItem: QuotationItem) {
  let imageUrl = '/images/product-placeholder.png';
  if (quotationItem.mainImage) {
    imageUrl = quotationItem.mainImage;
  }
  if (!isUrlAbsolute(imageUrl)) {
    imageUrl = thumbUrl(imageUrl).toString();
  }

  return imageUrl;
}

export function wrapQuotationItem(item: QuotationItem, product?: CartItemProductForCart): CartAndQuotationItemWrapper {
  if (product && isInternal(item)) {
    return {
      type: 'quotation_internal',
      isQuotation: true,
      item,
      product
    };
  } else {
    return {
      type: 'quotation_external',
      isQuotation: true,
      item: item as QuotationItem<QuotationItemStatuses, QuotationItemSources.external>,
      product: undefined
    };
  }
}

export function getExternalQuotationItemStatusDisplayMessage(item: QuotationItem): string {
  switch (item.status) {
    case QuotationItemStatuses.declined:
      return  'Quotation is unsuccessful.';
    case QuotationItemStatuses.resolved:
      return  'Your Quotation is here!';
    case QuotationItemStatuses.pending:
      return  'Quoting your product...';
  }
  return "";
}

export function checkQuotationItemHasStock(item: QuotationItem, product?: Pick<Product, 'variantPaths' | 'stock'>) {
  if (!product || !isInternal(item)) {
    return true;
  }
  if (item.productSourceData.variantPath) {
    const stock =
      product.variantPaths[item.productSourceData.variantPath]?.stock || 0;
    return stock >= item.quantity;
  } else {
    return product.stock >= item.quantity;
  }
}