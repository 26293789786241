import React from "react";
import { useRouter } from 'next/router'
import { AiOutlineSearch } from 'react-icons/ai'
import classNames from 'classnames'
import { handleSearch } from '../lib/search'
import { genericTextChange } from '../utils/forms'
import { useRegionLinkPrefix } from '../hooks/region'
import {isBlank} from "../utils/string";
import * as gtag from "../lib/gtag";

interface SearchFormProps {
  buttonClassName?: string;
  fieldClassName?: string;
  placeholder?: string;
  autoFocus?: boolean;
}

export default function SearchForm({ buttonClassName, fieldClassName, placeholder='Search or Paste link E.g https://www.tokopedia.com/seller/item-name..', autoFocus=false}: SearchFormProps) {
  const [searchLocked, setSearchLocked] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  
  const router = useRouter();
  const regionLinkPrefix = useRegionLinkPrefix();

  React.useEffect(() => {
    // unlock search form when navigated away
    setSearchText('');
    setSearchLocked(false);
  }, [router.query]);

  const handleSearchWrapper = (e: React.SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
    e.preventDefault()
    e.stopPropagation()

    if (searchLocked) {
      return;
    }

    if (isBlank(searchText)) {
      setErrorMessage('Search text cannot be blank');
      return;
    }

    setSearchLocked(true)
    handleSearch(searchText, regionLinkPrefix)
    gtag.search(searchText);
  }

  if (!buttonClassName) {
    buttonClassName = 'btn btn-dark-custom border-radius-left-0 border-radius-right-1'
  }

  if (!fieldClassName) {
    fieldClassName = 'form-control border-radius-left-1 border-radius-right-0'
  }

  fieldClassName = classNames(fieldClassName, {
    'is-invalid': !!errorMessage
  })

  return <form onSubmit={handleSearchWrapper}>
    <div className={classNames('input-group', {
      'is-invalid': !!errorMessage
    })}>
      <input
        type="text"
        className={fieldClassName}
        placeholder={placeholder}
        value={searchText}
        onChange={genericTextChange(setSearchText)}
        disabled={searchLocked}
        autoFocus={autoFocus}
      />

      <button
        type="submit"
        className={buttonClassName}
        disabled={searchLocked}
      >
        <AiOutlineSearch />
        <span className="d-none d-md-inline">Search</span>
      </button>
    </div>
    {errorMessage && (
      <div className="invalid-feedback">
        {errorMessage}
      </div>
    )}


  </form>
}