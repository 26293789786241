import Router from 'next/router'
import {
  NOTIFICATION_TYPE_PAYMENT_RECEIVED,
  NOTIFICATION_TYPE_ORDER_PURCHASED,
  NOTIFICATION_TYPE_ORDER_AT_WAREHOUSE,
  NOTIFICATION_TYPE_ORDER_IN_TRANSIT,
  NOTIFICATION_TYPE_ORDER_PROMPT_RECEIPT,
  NOTIFICATION_TYPE_ORDER_DISPUTE_RESPOND,
  NOTIFICATION_TYPE_QUOTATION_ITEM_RESOLVED,
  NOTIFICATION_TYPE_QUOTATION_ITEM_DECLINED,
} from '../constants/notifications'
import {Notification} from "../types/models/notification";
import {isUrlAbsolute} from "../utils/url";
import {imageUrl} from "../utils/image";

export function handleNotificationClick(notification: Notification) {
  return () => {
    switch (notification.type) {
      case NOTIFICATION_TYPE_PAYMENT_RECEIVED:
      case NOTIFICATION_TYPE_ORDER_PURCHASED:
      case NOTIFICATION_TYPE_ORDER_AT_WAREHOUSE:
      case NOTIFICATION_TYPE_ORDER_IN_TRANSIT:
      case NOTIFICATION_TYPE_ORDER_PROMPT_RECEIPT:
      case NOTIFICATION_TYPE_ORDER_DISPUTE_RESPOND:
        Router.push(`/account/orders/${notification.metadata.orderRef}`)
        break;
      case NOTIFICATION_TYPE_QUOTATION_ITEM_RESOLVED:
      case NOTIFICATION_TYPE_QUOTATION_ITEM_DECLINED:
        Router.push(`/cart?itemId=${notification.metadata.quotationItemId}`)
        break;
    }
  }
}

export function getNotificationImageSrc(notification: Notification) {
  let imageSrc = '/images/product-placeholder.png';
  if (notification.image) {
    if (isUrlAbsolute(notification.image)) {
      imageSrc = notification.image;
    } else {
      imageSrc = imageUrl(notification.image);
    }
  }

  return imageSrc;
}