import Head from "next/head";
import React from "react";
import styles from "../../styles/layout_base.module.scss";

interface LayoutBaseProps {
  children: React.ReactNode;
}

export default function LayoutBase({ children }: LayoutBaseProps) {
  return (
    <>
      <Head>
        <title>Indo4ward</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
      </Head>

      <div className={styles.root}>{children}</div>
    </>
  );
}
