import React from "react";
import {imageFallbackUrl} from "../utils/image";

interface ImageFallbackUrlProps {
  fallbackSource?: string;
}

export function useImageFallbackUrl({fallbackSource}: ImageFallbackUrlProps = {}) {
  const eventHandler: React.ChangeEventHandler<HTMLImageElement> =
    React.useCallback((e: React.ChangeEvent<HTMLImageElement>) => {
      e.target.src = imageFallbackUrl(fallbackSource);
    }, [fallbackSource]);
  return eventHandler;
}